import service from "@/services/base.service";

export default {
  methods: {
    handleAction(value) {
      switch (value.type) {
        case "create":
          this.$router.push({ name: this.createRoute });
          break;
        case "view":
          this.$router.push({
            name: this.viewRoute,
            params: { id: value.data.id },
          });
          break;
        case "delete":
          this.deleteConfim(value.data.id);
          break;

        default:
          break;
      }
      console.log("action", value);
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteAction(id);
          }
        });
    },
    async deleteAction(id) {
      try {
        await (new service(this.serviceClass)).deleteData(id);
        this.$swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
      }
    },
    handleSelected(value) {
      console.log("selected", value);
    }
  }
};