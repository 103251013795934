import BaseTable from "@/components/BaseTable.vue";
import BaseTableAction from "@/actions/base.table";
import Service from "@/services/base.service";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      cardId: "",
      cardType: "",
      nrp: "",
      cardList: [],
      createRoute: "submission-kta-form",
      viewRoute: "submission-kta-detail",
      serviceClass: "card-application",
      columns: [
        // { field: "id", title: "ID", isUnique: true, type: "number" },
        { field: "kta_number", title: "No KTA" },
        { field: "card_type.name", title: "Jenis KTA" },
        { field: "person.nama_lengkap", title: "Nama" },
        { field: "person.dimension.name", title: "Matra" },
        { field: "person.rank.name", title: "Pangkat/Korps" },
        { field: "person.nip_nrp", title: "NRP/NBI/NIP" },
        { field: "person.position.name", title: "Jabatan" },
        { field: "person.unity.name", title: "Kesatuan" },
        { field: "createdAt", title: "Tanggal Pengajuan" },
        { field: "status", title: "Status" },
        { field: "actions", title: "Actions", align: "center" },
      ],
    };
  },
  mounted() {
    this.fetchCardType();
  },
  mixins: [BaseTableAction],
  methods: {
    async fetchCardType(filters = null) {
      try {
        const BaseService = new Service("master/jenis-kartu");
        const { data } = await BaseService.getListData(filters);
        this.cardList = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.cardId = this.cardList[0].id;
        this.cardType = this.cardList[0].name;
      }
    },
    createKta() {
      this.$store.commit("setCardType", this.cardType);
      this.$store.commit("setCardId", this.cardId);
      this.$store.commit("setNrp", this.nrp);
      this.$router.push({ name: this.createRoute });
    },
    goTo(item) {
      item.type = "view";
      item.data = item.value;
      this.handleActionButton(item);
    },
    handleActionButton(action) {
      console.log(action);
      if (action.type == "view") {
        this.$store.commit("setCardType", action.data.card_type.name);
        this.$store.commit("setCardId", action.data.card_type.id);
        this.$store.commit("setPersonId", action.data.person.id);
        this.$store.commit("setNrp", action.data.person.nip_nrp);
        this.$router.push({
          name: this.viewRoute,
          params: { id: action.data.person.id },
        });
      }
    },
  },
  watch: {
    cardId(newVal) {
      this.cardType = this.cardList.find((item) => item.id === newVal).name; // update localValue when the value prop changes
      console.log(this.cardType);
    },
  },
};
