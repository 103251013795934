<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      
      <div class="card mb-5 col-lg-6 offset-lg-3">
        <!--begin::Card body-->
        <div class="card-body ">
          <!--begin::Table-->
          <div class="input-group mb-3">
            <select v-model="cardId" id="id-jenis-kta" class="form-control">
              <option v-for="(value, index) in cardList" :value="value.id" :key="index">{{ value.name }}</option>
            </select>
            <input id="id-input-id-pengguna" v-model="nrp" type="text" class="form-control" placeholder="Masukan NRP">
            <a @click="createKta" id="id-cari-data" class="btn btn-success">Cari Data</a>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>

      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Table-->

          <base-table
            :columns="columns"
            :service="'card-application'"
            :createButton="false"
            @actionButton="handleActionButton"
          >
            <template #action-button="{ data }">
              <!-- Your custom action button code here -->
              <button @click="goTo(data)" class="btn btn-sm btn-light btn-flex btn-center btn-secondary text-nowrap">
                Lihat Detail
              </button>
            </template>
          </base-table>

          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import Script from "@/scripts/kta/submission-list";

export default {
  mixins: [Script],
}
</script>
